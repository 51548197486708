var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ApiLoadingController", {
    attrs: { fetch: _vm.fetch, params: _vm.matchId },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var data = ref.data
          return [
            _c("MatchSpectatorVideo", {
              attrs: { "round-num": _vm.roundNum, "vod-info": data }
            })
          ]
        }
      },
      {
        key: "no-data",
        fn: function() {
          return [
            _c("MatchVideoPage", {
              attrs: {
                "match-data": _vm.matchData.info,
                "match-id": _vm.matchId,
                "round-num": _vm.roundNum
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }