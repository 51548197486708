var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "embed-responsive embed-responsive-16by9" },
    [
      _vm.vodInfo.vod_id
        ? _c(_vm.replayComponent, {
            tag: "component",
            attrs: {
              "current-time": _vm.startTime,
              id: _vm.vodInfo.vod_id,
              "start-time": _vm.startTime,
              "end-time": _vm.endTime
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }